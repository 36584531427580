@import "./colors.scss";

.footer-final {
  text-align: center;
  height: 0.9375rem;
  color: $green;
  padding-top: 0.625rem;

  @media (max-width: 500px) {
  height: 0.5rem;
    font-size: 0.5rem;
  }
}

.contact-details {
  list-style: none;
  margin: 0;

  @media (max-width: 792px) {
    margin: 20px;
  }
}

.container-footer {
  font-size: 0.9375rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 250px;
  background-color: $green;
  color: $gold;
  padding: 0.625rem;

  @media (max-width: 792px) {
    display: block;
    height: 420px;
  }

  @media (max-width: 300px) {
    height: 480px;
  }
}