@import "./colors.scss";

.aboutus-image {
  height: 300px;
  margin: 0.625rem;
}

.container-about-us {
  display: flex;
  margin: 0.625rem;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }

  @media (max-width: 605px) {
    display: block;
  }

}

.container-title {
  margin-top: 2.5rem;
  color: $green;

  @media (max-width: 1000px) {
    margin-top: 0.2rem;
  }

  @media (max-width: 570px) {
    margin-top: 0.1rem;
  }

}

.about-title {
  margin: 0.625rem;
  color: $green;
}

.about-description {
  color: $green;
  margin-bottom: 0.9375rem;
}

.about-container {
  margin: 0 auto;
  width: 70%;

  @media (max-width: 1000px) {
    width: 85%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

}

.about-us {
  margin: 1.25rem;
  padding: 0.625rem;
}