@import "./colors.scss";

.treatment-row {
  display: flex;
}

.treatment-name {
  text-align: left;
}

.treatment-title {
  font-weight: bold;
}

.treatment-price {
  margin-left: auto;
  padding-left: 10px;
}

.treatment-title-name {
  text-align: left;
}

.category-group {
  min-width: 600px;
  max-width: 600px;
  margin: auto auto 1.25rem;

  @media (max-width: 660px) {
    min-width: 70%;
    max-width: 70%;
    font-size: 0.9rem;
  }

  @media (max-width: 516px) {
    min-width: 80%;
    max-width: 80%;
    font-size: 0.7rem;
  }

  @media (max-width: 360px) {
    min-width: 90%;
    max-width: 90%;
    font-size: 0.5rem;
  }
}

.category-name {
  margin-bottom: 0.625rem;
  font-weight: bold;
  font-size: 1.2rem;

  @media (max-width: 516px) {
    font-size: 0.8rem;
  }
}

.price-container {
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  color: $green;
  margin: 0 auto;
}

.pricing-page {
  background-image: url("las.jpg");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
