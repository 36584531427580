* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  text-align: center;
  font-family: 'Trirong', serif;
  outline: none;
}

body{
  overflow-x: hidden;
}

