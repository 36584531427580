@import "./colors.scss";

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
    max-width: 60%;
  }
}

.row .box {
  margin-right: 0.9rem;

  @media (max-width: 700px) {
    margin-right: 0.3rem;
  }

  @media (max-width: 450px) {
    margin-left: 0.05rem;
  }
}

.box-image {
  max-width: 100%;
  margin-top: 0.3125rem;
}

.box a .box-title {
  font-size: 1.3rem;
  font-weight: lighter;
  color: $green;
  text-align: center;
  text-decoration: none;

  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media (max-width: 850px) {
    font-size: 0.8rem;
  }

  @media (max-width: 650px) {
    font-size: 0.75rem;
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
}

.box a:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.container-title-treatment {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;

  @media (max-width: 1100px) {
    width: 90%;
  }
}

.about-description {
  margin: 0 auto;
  text-align: left;
}

.about-image {
  height: 300px;
  margin-top: 10px;

  @media (max-width: 500px) {
    height: 200px;
  }

  @media (max-width: 330px) {
    height: 130px;
  }
}

h2.box-title {
  font-size: 2rem;
}