@import "./colors.scss";

.middle-text-content {
  min-height: 230px;
  padding-top: 70px;

  @media (max-width: 700px) {
    padding-top: 65px;
    margin-bottom: 20px;
  }

  @media (max-width: 530px) {
    padding-top: 40px;
  }

  @media (max-width: 430px) {
    padding-top: 30px;
  }

  @media (max-width: 330px) {
    padding-top: 20px;
  }
}

.middle-text-content p {
  font-size: 1.25rem;
  color: $green;
  border: 3px solid $green;
  width: 70%;
  text-align: center;
  padding: 1.25rem;
  margin: 0 auto;

  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 700px) {
    padding: 1rem;
  }

  @media (max-width: 530px) {
    padding: 0.7rem;
  }

  @media (max-width: 430px) {
    width: 80%;
  }
}