@import "./colors.scss";

.header-title {
  height: 60px;
  font-size: 1.25rem;
  color: $green;
  margin: 0 auto;
  text-align: center;
  padding: 0.625rem;

  @media (max-width: 750px) {
    font-size: 1rem;
    height: 40px;
  }

  @media (max-width: 550px) {
    height: 30px;
  }

  @media (max-width: 350px) {
    height: 25px;
    margin-bottom: 10px;
  }

  @media (max-width: 275px) {
    font-size: 0.8rem;
  }

  @media (max-width: 228px) {
    font-size: 0.5rem;
  }
}